<template>
  <v-card light>
    <!-- DELETED TEAM -->
    <v-container fluid v-if="team.isDeleted">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{team.name}} is no longer registered for {{tournament.name}}</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- LOCKED -->
    <v-container fluid v-else-if="tournament.locked && !user.vbl">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{tournament.name}} has been locked for editing</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- SEND REQUEST -->
    <v-container fluid v-else-if="(!currentDivision.regOpen || tournament.bidProgram || tournament.props.includes('noDivisionChange')) && !userIsAdmin">
      <v-row dense>
        <v-col>
          <v-card color="grey lighten-4">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <h3 style="width: 100%">
                    Division Change
                  </h3>
                </v-col>
                <v-col cols="12" v-if="!tournament.bidProgram">
                  You can only make changes up until registration closes. Any changes now, need to be approved by the tournament director.
                  Please enter your detailed request below and we will notify the tournament director.
                </v-col>
                <v-col cols="12" v-else>
                  Any changes to a bid tournament registration, need to be approved by the tournament director.
                  Please enter your detailed request below and we will notify the tournament director.
                </v-col>
                <change-request
                  ref="changeRequest"
                  :teamId="team.id"
                  :busy="busy"
                  type="Division Change"
                  @valid-change="onChangeRequestValidChange"
                ></change-request>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" >
          <v-btn
            color="color3 color3Text--text"
            @click="onSubmitRequest"
            :loading="busy"
            :disabled="busy || !validRequest"
          >Submit request</v-btn>
          <v-btn
            @click="reset()"
            :disabled="busy || !validRequest"
          >Cancel</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-else>
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>Select a new division</h3>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                :items="divisionChoices"
                v-model="newDivision"
                label="New division"
                hint="Select the division to move to"
                persistent-hint
                item-text="name"
                return-object
                clearable
                color="color3"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" >
          <v-btn
            color="color3 color3Text--text"
            :disabled="!team || !(newDivision || newDivisionId || wl)"
            @click="makeTheMove"
            :loading="busy"
          >
            Move the team
          </v-btn>
          <v-btn
            v-if="false"
            @click="cancel"
            :disabled="busy"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" cols="12" sm="4" offset-sm="4">
          <v-alert type="error" v-model="unauthorized" dismissible>
            I'm sorry you are not authorized to make this change.
          </v-alert>
        </v-col>
      </v-row>
      <template v-if="userIsAdmin">
        <v-row dense>
          <v-col class="text-right" cols="12" >
            <v-btn
              color="color3"
              class="xsmall ma-0 pa-o"
              text
              @click.stop="advanced = !advanced"
            >
              advanced
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <div v-if="advanced">
            <v-row dense>
              <v-col cols="6" offset="6" sm="3" offset-sm="9" md="2" offset-md="10">
                <v-text-field
                  label="New Division Id"
                  type="number"
                  v-model="newDivisionId"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" offset="6" sm="3" offset-sm="9" md="2" offset-md="10">
                <v-checkbox label="Move to waitlist" v-model="wl" value="value" color="color3"></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import ChangeRequestVue from './ChangeRequest.vue'
import { mapGetters } from 'vuex'
import * as mutations from '../../store/MutationTypes'

export default {
  props: ['tournament', 'team', 'currentDivision'],
  data () {
    return {
      newDivision: null,
      newDivisionId: null,
      busy: false,
      unauthorized: false,
      validRequest: false,
      advanced: false,
      wl: false
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'order', 'getTournamentSubscription', 'getTeamToken']),
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    userIsAdmin () {
      return this.user && !this.publicView && this.user.isTournamentAdmin(this.tournament)
    },
    divisions () {
      return this.tournament.divisions
    },
    divisionChoices () {
      const base = this.currentDivision ? this.divisions.filter((d) => {
        return d.id !== this.currentDivision.id && !d.canceled
      }) : this.divisions

      return base.map(d => {
        return {
          name: d.name,
          id: d.id,
          disabled: d.isFull && !this.userIsAdmin
        }
      })
    },
    credentials () {
      return {
        orderId: this.order ? this.order.id : null,
        email: this.order ? this.order.emailReceiptTo : null
      }
    },
    regOpen () {
      return this.currentDivision.regOpen
    },
    request () {
      return (!this.currentDivision.regOpen || !!this.tournament.bidProgram) && !this.userIsAdmin
    }
  },
  methods: {
    makeTheMove () {
      if (!this.team || !(this.newDivision || this.newDivisionId || this.wl)) return
      if (this.wl) {
        this.moveToWaitlist()
        return
      }
      this.busy = true

      this.$VBL.team.changeDivisions(this.team.id, this.newDivisionId || this.newDivision.id, this.credentials, this.teamToken)
        .then((response) => {
          this.team.notes = response.data
          const subscriptiion = this.getTournamentSubscription(this.tournament.id)
          if (!subscriptiion) {
            this.tournament.changeDivisions(this.team.id, this.newDivision.id)
          }
          this.$emit('division-changed')
          this.newDivision = null
        })
        .catch((error) => {
          console.log(error.response)
          this.unauthorized = error.response.status === 401
        })
        .finally(() => {
          this.busy = false
        })
    },
    moveToWaitlist () {
      this.busy = true
      const dto = {
        teamIds: [this.team.id],
        in: false,
        notify: false,
        charge: false
      }
      this.$VBL.waitlist(dto)
        .then(r => {
          this.team.waitlist = true
          this.$emit('division-changed')
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.busy = false })
    },
    onSubmitRequest () {
      this.busy = true
      this.$refs.changeRequest.submitRequest()
        .then(response => {
          this.$emit('request-submitted')
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    reset () {
      this.newDivision = null
      this.unauthorized = false
      this.busy = false
      if (this.$refs.changeRequest) this.$refs.changeRequest.reset()
    },
    onChangeRequestValidChange (val) {
      this.validRequest = val
    }
  },
  watch: {
    busy: function (val) {
      const mutation = val ? mutations.LOADING : mutations.LOADING_COMPLETE
      this.$store.commit(mutation, `DIVISION_CHANGE_${this.team.id}`)
    }
  },
  components: {
    'change-request': ChangeRequestVue
  }
}
</script>

<style>

</style>
